import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSlideYTransition } from 'vuetify/lib/components/transitions';
import Ripple from 'vuetify/lib/directives/ripple';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('div',{staticClass:"runnit-video-container",class:{
			clickable: !_vm.noClick,
			'background-gradient': _vm.isSelected
		},style:({
			...(_vm.isSelected && { padding: '3px' })
		}),on:{"click":_vm.onClick}},[_c(VSlideYTransition,[(_vm.isSelectionMode)?_c('div',{directives:[{def: Ripple,name:"ripple",rawName:"v-ripple"}],staticClass:"selection-chip",class:{ 'background-gradient': _vm.isSelected },style:({
					...(_vm.$vuetify.breakpoint.mdAndUp && {
						width: '40px',
						height: '40px',
					}),
				})},[(_vm.isSelected)?_c(VIcon,{staticStyle:{"font-size":"27px","color":"white"}},[_vm._v(" mdi-check ")]):(_vm.isMultiSelection)?_c(VIcon,{staticStyle:{"font-size":"27px"},style:({
						color: hover ? 'var(--primary-green)' : 'var(--grey-300)',
					})},[_vm._v(" mdi-plus-circle-outline ")]):(_vm.isSingleSelection)?_c(VIcon,{staticStyle:{"font-size":"27px"},style:({
						color: hover ? 'var(--primary-green)' : 'var(--grey-300)',
					})},[_vm._v(" mdi-check ")]):_vm._e()],1):_vm._e()]),(!_vm.thumbSrc && !_vm.gifSrc && !_vm.vidSrc)?_c('div',[_vm._v("no src")]):(_vm.thumbSrc || _vm.gifSrc)?_c('div',{staticStyle:{"position":"relative","width":"100%","height":"100%","display":"flex"}},[_c('img',{staticStyle:{"width":"100%","border-radius":"var(--global-border-radius-md)"},attrs:{"src":_vm.thumbSrc || _vm.gifSrc}}),(hover && _vm.gifSrc && _vm.thumbSrc)?_c('img',{staticStyle:{"width":"100%","position":"absolute","top":"0","left":"0","border-radius":"var(--global-border-radius-md)"},attrs:{"src":_vm.gifSrc}}):_vm._e()]):_c('video',{ref:"videoPlayer",staticStyle:{"width":"100%","border-radius":"var(--global-border-radius-md)"},attrs:{"controls":""}},[_c('source',{attrs:{"src":_vm.vidSrc,"type":_vm.nodeRunResult.contentType}}),_c('span',[_vm._v("Your browser does not support the video tag.")])])],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }