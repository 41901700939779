
import Vue from 'vue';
import _sortBy from 'lodash/sortBy';
import { functions } from '@/firebase';
import { mapActions, mapState } from 'vuex';
import { SNACKBAR_STATUS } from '@/constants/constants';
import { FEATURED_RUNNIT_NODE_DEF_IDS } from '@/views/Runnits/constants';
import { RunnitNodeDef, RunnitNodeDefToolType } from '@run-diffusion/shared';
import { RUNNIT_NODE_DEF_TOOL_TYPE, RUNNITS_PUBLISHED_STATE, RunnitsPublishedState } from '@/constants/enums';
import OfferingCard from '@/components/OfferingCard.vue';
import GlassButton from '@/components/base/GlassButton.vue';
import LoadingState from '@/components/states/LoadingState.vue';
import RunnitTypeMultiSelect from '@/views/Runnits/RunnitTypeMultiSelect.vue';
import ComplexBackground from '@/components/designElements/ComplexBackground.vue';
import EmptyStateCircleBackground from '@/components/states/EmptyStateCircleBackground.vue';
import RunnitDuplicateEditNodeDefInternalEditor from '@/views/Runnits/RunnitSettings/internalAdminOnly/RunnitDuplicateEditNodeDefInternalEditor.vue';

export default Vue.extend({
	name: 'RunnitToolsLibraryDialog',
	props: {
		value: { type: Boolean, default: false },
		persistent: { type: Boolean, default: false },
		maxWidth: { type: [Number, String], default: '1000px' },
	},
	data () {
		return {
			RUNNITS_PUBLISHED_STATE,
			RUNNIT_NODE_DEF_TOOL_TYPE,
			FEATURED_RUNNIT_NODE_DEF_IDS,
			open: false,
			toolSearchValue: '',
			selectedTypes: [
				RUNNIT_NODE_DEF_TOOL_TYPE.CURATED,
				RUNNIT_NODE_DEF_TOOL_TYPE.CORE,
			],

			filteredRunnitNodeDefs: [],

			nodeDefInternalEditorDialog: {
				open: false,
				selectedNodeDef: null,
			},

			publishedStateFilter: RUNNITS_PUBLISHED_STATE.PUBLISHED,
		};
	},
	computed: {
		...mapState([
			'user',
			'runnitState',
			'publicRunnitNodeDefs',
			'loadingPublicRunnitNodeDefs',
			'teamRunnitNodeDefs',
			'loadingTeamRunnitNodeDefs',
		]),
	},
	watch: {
		value: {
			immediate: true,
			handler (newVal: boolean, oldVal: boolean) {
				this.open = !!newVal;
			},
		},
		publicRunnitNodeDefs: {
			immediate: true,
			handler (newVal: RunnitNodeDef[], oldVal: RunnitNodeDef[]) {
				this.filteredRunnitNodeDefs = [
					...(newVal || []),
				];
				this.applyFilters();
			}
		},
	},
	methods: {
		...mapActions([
			'updateSnackbar',
		]),
		setOpen (val: boolean) {
			this.open = !!val;
			if (this.open !== this.value) {
				this.$emit('input', this.open);
			}
		},
		onCancel () {
			this.setOpen(false);
			this.toolSearchValue = '';
		},
		onSelectionChosen () {
			this.onCancel();
		},
		handleMultiSelectFilter (selectedTypes: RunnitNodeDefToolType[]) {
			this.selectedTypes = selectedTypes;
			this.applyFilters();
		},
		handleSearchInput (searchValue) {
			this.toolSearchValue = searchValue;
			this.applyFilters();
		},
		handlePublishedFilterChange (publishedState: RunnitsPublishedState) {
			this.publishedStateFilter = publishedState;
			this.applyFilters();
		},
		applyFilters () {
			this.filteredRunnitNodeDefs = [];

			[
				...this.publicRunnitNodeDefs,
				...this.teamRunnitNodeDefs,
			].forEach((runnitNodeDef: RunnitNodeDef) => {
				let matchesSearch: boolean, matchesPublishedState: boolean, matchesSelectedTypes: boolean;

				if (this.toolSearchValue) {
					matchesSearch = runnitNodeDef.title.toLowerCase().includes(this.toolSearchValue.toLowerCase());
				} else {
					matchesSearch = true;
				}

				if (this.publishedStateFilter === RUNNITS_PUBLISHED_STATE.PUBLISHED) {
					matchesPublishedState = !!runnitNodeDef.publishedAt;
				} else if (this.publishedStateFilter === RUNNITS_PUBLISHED_STATE.NOT_PUBLISHED) {
					matchesPublishedState = !runnitNodeDef.publishedAt;
				} else {
					matchesPublishedState = false;
				}

				if (this.selectedTypes && this.selectedTypes.length) {
					matchesSelectedTypes = this.selectedTypes.includes(runnitNodeDef.type);
				} else {
					matchesSelectedTypes = false;
				}

				if (matchesSearch && matchesPublishedState && matchesSelectedTypes) {
					this.filteredRunnitNodeDefs.push(runnitNodeDef);
				}
			});

			this.filteredRunnitNodeDefs = _sortBy(this.filteredRunnitNodeDefs, 'sortOrder');
		},
		async onToolCardClick (runnitNodeDef: RunnitNodeDef) {
			const onError: Function = (e) => {
				console.error(e);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: 'Error adding a tool to this runnit, please reach out to report issues by clicking the support button in our top toolbar',
					show: true,
				});
			};
			try {
				this.onCancel();
				this.$emit('on-adding-tool-loading', true);
				const functionRef = functions
					.httpsCallable('addNodeToRunnit');
				const { success } = (await functionRef({
					runnitId: this.$route.params.runnitId,
					nodeDefId: runnitNodeDef.id,
				})).data;
				if (!success) {
					onError(new Error('addNodeToRunnit returned success: false'));
				}
				this.updateSnackbar({
					status: SNACKBAR_STATUS.SUCCESS,
					message: 'New tool added to runnit!',
					show: true,
				});
			} catch (e) {
				onError(e);
			} finally {
				this.$emit('on-adding-tool-loading', false);
			}
		},
		toggleAdminNodeDefEditor (selectedNodeDef: RunnitNodeDef) {
			this.nodeDefInternalEditorDialog.open = true;
			this.nodeDefInternalEditorDialog.selectedNodeDef = selectedNodeDef;
		},
	},
	components: {
		GlassButton,
		LoadingState,
		OfferingCard,
		ComplexBackground,
		RunnitTypeMultiSelect,
		EmptyStateCircleBackground,
		RunnitDuplicateEditNodeDefInternalEditor,
	},
});
