
import Vue from 'vue';

export default Vue.extend({
	name: 'NumberWithIncrementer',
	props: {
		value: { type: Number, default: null },
		max: { type: Number, default: null },
		min: { type: Number, default: null },
		step: { type: Number, default: null },
		disabled: { type: Boolean, default: false },
		padding: { type: String, default: '0 14px 0 12px' },
	},
	computed: {
		computedMin () {
			return this.min > 0 ? this.min : 1;
		},
		computedMax () {
			return this.max > 0 ? this.max : Math.max(this.computedMin, 1);
		},
		showMaxWarning () {
			return this.computedMax > 1 && this.value > 0 && this.value === this.computedMax;
		},
	},
	methods: {
		onIncrement (isUp: boolean) {
			const stepNum: number = this.step > 0 ? this.step : 1;

			let incrementedVal: number = this.value || 0;
			if (isUp) incrementedVal += stepNum;
			else incrementedVal -= stepNum;
			if (incrementedVal > this.computedMax || incrementedVal < this.computedMin) {
				return;
			}

			let emittedVal: number = undefined;
			if (incrementedVal <= this.computedMax && incrementedVal >= this.computedMin) {
				emittedVal = incrementedVal;
			} else if (this.computedMin > 0) {
				emittedVal = this.computedMin;
			} else {
				emittedVal = 1;
			}
			if (emittedVal !== undefined && emittedVal !== this.value) {
				this.$emit('input', emittedVal);
			}
		},
	},
});
