import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VCard,{staticClass:"mx-4 pb-9 full-width",class:{
		'px-9 pt-7': _vm.$vuetify.breakpoint.mdAndUp,
		'px-5 pt-6': _vm.$vuetify.breakpoint.smAndDown,
	}},[_c('div',{staticClass:"d-flex justify-space-between flex-wrap"},[(_vm.workshopsEnabled)?_c(VCardTitle,{staticClass:"pt-0 px-0 mt-0",style:({
				fontSize: _vm.$vuetify.breakpoint.xsOnly ? '17px': '20px',
			})},[_c(VIcon,{staticStyle:{"color":"var(--grey-500)"},attrs:{"left":""}},[_vm._v("mdi-bulletin-board")]),_vm._v(" Workshops ")],1):_vm._e(),((
				_vm.isTeamAdmin &&
				_vm.maxWorkshopsReached
			))?_c('info-message-small',{staticClass:"mb-3",staticStyle:{"width":"600px"},attrs:{"super-primary":""}},[_vm._v(" You have reached the maximum number of workshops for your team. Please reach out to "),_c('b',[_vm._v("admin@rundiffusion.com")]),_vm._v(" for an increase. ")]):_vm._e(),((
				_vm.isTeamAdmin &&
				_vm.workshopsEnabled
			))?_c(VTooltip,{attrs:{"bottom":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('base-button',_vm._g(_vm._b({staticClass:"px-7",attrs:{"disabled":!_vm.isTeamActive,"secondary":""},on:{"click":_vm.openAddWorkshopDrawer}},'base-button',attrs,false),on),[_vm._v(" Add ")])]}}],null,false,311456670)},[_c('div',{staticClass:"tooltip-max-width"},[_vm._v("Add a new Workshop")])]):_vm._e(),(!_vm.localWorkshops.length)?_c('div',{staticClass:"workshops-empty-container"},[_c(VIcon,{staticClass:"mb-4",staticStyle:{"color":"var(--grey-800)"},attrs:{"size":"100"}},[_vm._v("mdi-bulletin-board")]),_c('div',{staticClass:"workshops-empty-title"},[(_vm.workshopsEnabled)?_c('span',[_vm._v(" No workshops yet ")]):_c('span',[_vm._v(" Interested in Workshops? ")])]),_c('div',{staticClass:"workshops-empty-text"},[(!_vm.workshopsEnabled)?_c('span',[_vm._v(" Inquire about workshops by reaching out to sales@rundiffusion.com! ")]):(_vm.isTeamAdmin)?_c('span',[_vm._v(" Click the "),_c('span',{staticStyle:{"font-weight":"bold","color":"var(--grey-500)"}},[_vm._v("Add")]),_vm._v(" button to create one. ")]):_c('span',[_vm._v(" Please "),_c('span',{staticStyle:{"font-weight":"bold","color":"var(--grey-500)"}},[_vm._v("reach out to an admin")]),_vm._v(" on your team to create one ")])])],1):_vm._e()],1),(_vm.loading)?_c('loading-state'):(_vm.hasError)?_c('error-state'):(_vm.localWorkshops.length)?_c(VRow,_vm._l((_vm.workshops),function(workshop){return _c(VCol,{key:workshop.id,staticClass:"mt-4",attrs:{"cols":"12","sm":"4"}},[_c('workshop-card',{attrs:{"workshop":workshop},on:{"on-edit-workshop":_vm.onEditWorkshop}})],1)}),1):_vm._e(),_c('add-edit-workshop',{attrs:{"value":_vm.addEditWorkshopConfig.open,"is-edit-mode":_vm.addEditWorkshopConfig.editMode,"workshop":_vm.addEditWorkshopConfig.workshop},on:{"input":_vm.onAddEditInput,"on-close":function($event){_vm.addEditWorkshopConfig.workshop = null}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }