import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"image-upload-container"},[_c('runnit-dynamic-field-label-row',{attrs:{"field":_vm.field},scopedSlots:_vm._u([{key:"actions",fn:function(){return [(!_vm._get(_vm.field, 'required'))?_c('runnit-setting-helper-btn',{staticClass:"mr-2 ml-2",attrs:{"disabled":_vm.isUploading,"min-width":"70px","selected":_vm.imgToImgSelection === _vm.IMG_TO_IMG.NONE},on:{"click":function($event){_vm.imgToImgSelection = _vm.IMG_TO_IMG.NONE}}},[_vm._v(" None ")]):_vm._e(),_c('runnit-setting-helper-btn',{staticClass:"mr-2",attrs:{"disabled":_vm.isUploading,"min-width":"70px","selected":_vm.imgToImgSelection === _vm.IMG_TO_IMG.SELECT},on:{"click":function($event){_vm.imgToImgSelection = _vm.IMG_TO_IMG.SELECT}}},[_vm._v(" Select ")]),_c('runnit-setting-helper-btn',{attrs:{"disabled":_vm.isUploading,"min-width":"70px","selected":_vm.imgToImgSelection === _vm.IMG_TO_IMG.UPLOAD},on:{"click":_vm.onUploadSelection}},[_vm._v(" Upload ")])]},proxy:true}])}),_c('transition-group',{staticClass:"transition-group-flex-container",attrs:{"name":"slide-fade-absolute","tag":"div"}},[(_vm.showNoneOptionActionSection)?_c('list-item',_vm._b({key:"option-none",attrs:{"container-styles":{ color: 'var(--grey-600)', marginBottom: 0, fontSize: '13px', lineHeight: '18px', },"content-container-styles":{ width: '100%' }}},'list-item',{
				'icon': 'mdi-information-slab-circle-outline',
				'icon-color': 'var(--grey-600)',
				'icon-size': '14px',
				'icon-style': { marginRight: '7px !important', },
			},false),[_vm._v(" Upload an image or select from the library to use "),_c('div',{staticClass:"gradient-text",staticStyle:{"display":"inline-block"}},[_vm._v("Image to Image")])]):_vm._e(),(_vm.showSelectOptionActionSection)?_c('image-input-selected-image-container',{key:"option-select",attrs:{"library-text":"Library","value":_vm.value,"hasInpainting":_vm.hasInpainting,"hasStrength":_vm.hasStrength},on:{"on-clear":_vm.clearValueAndStartSelectSelectionMode,"on-library":function($event){return _vm.startLibrarySelectionMode(_vm.IMAGE_GALLERY_DIALOG_NAV.GENERATIONS)},"on-strength-change":_vm.onStrengthChange}}):_vm._e(),(_vm.showUploadOptionActionSection)?[(_vm.value)?_c('image-input-selected-image-container',{key:"option-upload",attrs:{"library-text":"Uploads","value":_vm.value,"hasInpainting":_vm.hasInpainting,"hasStrength":_vm.hasStrength},on:{"on-clear":function($event){return _vm.onInput(null, null, null)},"on-library":function($event){return _vm.startLibrarySelectionMode(_vm.IMAGE_GALLERY_DIALOG_NAV.UPLOADS)}}}):_c('div',{key:"option-upload",staticClass:"image-upload-square",class:{
					'being-dragged-over': _vm.isBeingDraggedOver,
				},staticStyle:{"margin":"4px 0 0 0"},on:{"dragover":function($event){$event.preventDefault();return _vm.handleDragOver.apply(null, arguments)},"dragleave":function($event){$event.preventDefault();return _vm.handleDragLeave.apply(null, arguments)},"drop":function($event){$event.preventDefault();return _vm.handleDrop.apply(null, arguments)},"click":_vm.openFileDialog}},[_c('div',{staticClass:"d-flex flex-column justify-center align-center"},[_c('div',{staticClass:"mb-2"},[(_vm.isUploading)?_c(VProgressCircular,{attrs:{"indeterminate":true,"size":"24","width":"2"}}):(_vm.isBeingDraggedOver)?_c(VIcon,{staticClass:"gradient-text",staticStyle:{"font-size":"32px"}},[_vm._v("mdi-image-plus")]):_c(VIcon,{staticStyle:{"color":"var(--grey-650)","font-size":"28px"}},[_vm._v("mdi-image-plus-outline")])],1),_c('div',{staticClass:"upload-square-text-container",style:({
							...(_vm.isBeingDraggedOver && {
								color: 'white',
							}),
						})},[(_vm.isUploading)?_c('span',[_vm._v("Uploading...")]):_c('span',[_vm._v("Click or drag image from your computer to "),_c('span',{class:{ 'gradient-text': !_vm.isBeingDraggedOver }},[_vm._v("Upload")])])])])])]:_vm._e()],2),_c(VTextField,{staticClass:"pl-2 pt-2 pr-0 pb-0 my-0 rules-only-validation-text-field",class:_vm.field.required ? 'required' : '',attrs:{"rules":_vm.field.required ? _vm.rules.requiredFileInput : _vm.rules.optionalFileInput,"value":_vm.imageValue,"readonly":"","required":"","hide-details":"auto","single-line":""}}),_c('input',{ref:"fileInput",staticStyle:{"display":"none"},attrs:{"type":"file","id":"fileElem","multiple":false,"accept":"image/*"},on:{"change":_vm.onHiddenFileInputChange}}),(_vm.runnitState.inpaintingEditorOpen)?_c('inpainting-editor',{staticClass:"absolutely-positioned-anchor",attrs:{"maskURL":_vm.runnitState.inpaintingMaskUrl,"nodeId":_vm.nodeId},on:{"input":_vm.onInpaintingMaskChange}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }