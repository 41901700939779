
import Vue from 'vue';
import { db } from '@/firebase';
import _truncate from 'lodash/truncate';
import _range from 'lodash/range';
import _mapKeys from 'lodash/mapKeys';
import _cloneDeep from 'lodash/cloneDeep';
import { mapActions, mapState } from 'vuex';
import { get$bindFirestoreOptions } from '@/mixins';
import { SNACKBAR_STATUS } from '@/constants/constants';
import {
	Avatar,
	Runnit,
	RunnitNode,
	RunnitNodeRun,
	RunnitNodeRunResult,
	RunnitUpload,
} from '@run-diffusion/shared';
import {
	SETTING_ACTIONS,
	SELECTED_COLUMNS_MAP,
	SELECTED_IMAGE_MODE,
	SELECTED_COLUMN_MENU_ITEMS,
	SELECTED_IMAGE_MODE_MENU_ITEMS,
} from '@/views/Runnits/constants';
import {
	AVATAR_USE_CASE,
	IMAGE_UPLOAD_MODE,
	RUNNIT_NODE_FIELD_TYPE,
	RUNNIT_NODE_RUN_STATE,
	RUNNIT_NODE_STATIC_FIELDS_KEY,
} from '@/constants/enums';
import { RunnitState } from '@/store';
import { IMAGE_GALLERY_DIALOG_NAV, ImageGalleryDialogNav } from '@/components/ImageGallery/utils';
import RunnitImage from '@/views/Runnits/RunnitImage.vue';
import GlassButton from '@/components/base/GlassButton.vue';
import LoadingState from '@/components/states/LoadingState.vue';
import ImageInfo from '@/components/ImageGallery/ImageInfo.vue';
import BaseStyledMenu from '@/components/base/BaseStyledMenu.vue';
import RunnitNavTabs from '@/views/Runnits/base/RunnitNavTabs.vue';
import ComplexBackground from '@/components/designElements/ComplexBackground.vue';
import RunnitImageInfoCarouselDialog from '@/views/Runnits/RunnitImageInfoCarouselDialog.vue';
import ImageGalleryLoadMoreBtnsRow from '@/components/ImageGallery/ImageGalleryLoadMoreBtnsRow.vue';
import ImageUpload from '@/views/Runnits/RunnitSettings/ImageInput/ImageUpload.vue';
import { RunnitsImageSelectMixin } from '@/mixins/RunnitsImageSelectMixin';
import RunnitResult from '@/views/Runnits/RunnitResult.vue';

export default Vue.extend({
	name: 'ImageGallery',
	props: {
		elIdSuffix: { type: String, required: true }, // to prefix html element IDs in the DOM
		height: { type: String, default: '100%' },
		showTopSettings: { type: Boolean, default: false },
		insideDrawer: { type: Boolean, default: false },
		isAppDrawerMode: { type: Boolean, default: true },
		insideNode: { type: Boolean, default: false },
		runsGalleryRunnit: { type: Object, default: null },
		runsGalleryNode: { type: Object, default: null },
		navTab: { type: String, default: null },
	},
	mixins: [
		RunnitsImageSelectMixin,
	],
	data () {
		return {
			IMAGE_GALLERY_DIALOG_NAV,
			SETTING_ACTIONS,
			SELECTED_IMAGE_MODE,
			RUNNIT_NODE_FIELD_TYPE,
			RUNNIT_NODE_RUN_STATE,
			FINAL_NODE_RUN_STATES: [
				RUNNIT_NODE_RUN_STATE.NSFW,
				RUNNIT_NODE_RUN_STATE.ERROR,
				RUNNIT_NODE_RUN_STATE.DONE,
			],
			PENDING_NODE_RUN_STATES: [
				RUNNIT_NODE_RUN_STATE.INIT,
				RUNNIT_NODE_RUN_STATE.QUEUED,
			],
			IMAGE_UPLOAD_MODE,
			AVATAR_USE_CASE,
			selectedImageModeMenuItems: SELECTED_IMAGE_MODE_MENU_ITEMS,

			selectColumnsMap: SELECTED_COLUMNS_MAP,
			selectedColumnsMenuItems: SELECTED_COLUMN_MENU_ITEMS,

			isIntersected: false,
			imageInfoCarouselConfig: {
				dialogOpen: false,
				nodeRun: null,
				nodeRuns: null,
				nodeRunResult: null,
			},
			draftLoadingTimerStartAtMillisMap: {}, // RunnitNodeRun.id -> milliseconds

			realtimeNodeRunsLoading: false,
			realtimeNodeRuns: [],
			oldRealtimeNodeRunIds: [], // $bind has a problem with immutability, need to keep track of the old IDs to see what needs to go from `realtime` to `more` arrays
			realtimeNodeRunsLimit: 100,
			canLoadMoreNodeRuns: false,
			loadingMoreNodeRuns: false,
			moreNodeRuns: [],
			lastMoreNodeRunsDoc: null,
			moreNodeRunsLimit: 48,

			isInitializedRunnitUploads: false,
			realtimeUploadsEndBeforeSnapshot: null, // cut off cursor between `realtime` & `more` queries
			realtimeUploadsLoading: false,
			realtimeUploads: [],
			oldRealtimeUploadIds: [], // $bind has a problem with immutability, need to keep track of the old IDs to see what needs to go from `realtime` to `more` arrays
			realtimeUploadsLimit: 100,
			canLoadMoreUploads: true,
			loadingMoreUploads: false,
			moreUploads: [],
			lastMoreUploadsDoc: null,
			moreUploadsLimit: 24,

			// Avatars
			isInitializedAvatars: false,
			realtimeAvatarsEndBeforeSnapshot: null, // cut off cursor between `realtime` & `more` queries
			realtimeAvatarsLoading: false,
			realtimeAvatars: [],
			oldRealtimeAvatarsIds: [], // $bind has a problem with immutability, need to keep track of the old IDs to see what needs to go from `realtime` to `more` arrays
			realtimeAvatarsLimit: 100,
			canLoadMoreAvatars: true,
			loadingMoreAvatars: false,
			moreAvatars: [],
			lastMoreAvatarsDoc: null,
			moreAvatarsLimit: 24,

			currNavTab: null,
			imageGalleryNavTabs: [
				{
					id: IMAGE_GALLERY_DIALOG_NAV.GENERATIONS,
					label: 'Your Generations',
					icon: 'mdi-image-multiple-outline',
				},
				{
					id: IMAGE_GALLERY_DIALOG_NAV.UPLOADS,
					label: 'Uploads',
					icon: 'mdi-cloud-upload-outline',
				},
				// { // By default this tab isn't used for the runnit workflows so we don't want them visible
				// 	id: IMAGE_GALLERY_DIALOG_NAV.AVATARS,
				// 	label: 'Avatars',
				// 	icon: 'mdi-cloud-upload-outline',
				// },
			],
		};
	},
	computed: {
		...mapState([
			'user',
			'runnitState',
			'draftRunnitNodeRun',
		]),
		reversedRealtimeNodeRuns () {
			return [...this.realtimeNodeRuns].reverse();
		},
		showIsQueuingDraftRunnitNodeRunPlaceholderLoaders () {
			return !!(
				this.insideNode &&
				this.draftRunnitNodeRun &&
				this.runnitState.isQueuingDraftRunnitNodeRun &&
				this.runsGalleryRunnit &&
				this.runsGalleryNode &&
				this.runsGalleryNode.id === this.draftRunnitNodeRun.nodeId
			);
		},
		insideDialog () {
			return !!(
				!this.insideDrawer &&
				!this.insideNode
			);
		},
	},
	watch: {
		showIsQueuingDraftRunnitNodeRunPlaceholderLoaders: {
			immediate: true,
			handler (newVal: boolean, oldVal: boolean) {
				if (newVal && newVal !== oldVal && this.draftRunnitNodeRun) {
					this.draftLoadingTimerStartAtMillisMap[this.draftRunnitNodeRun.id] = Date.now();
				}
			},
		},
		navTab: {
			immediate: true,
			handler (newVal, oldVal) {
				if (newVal !== oldVal) {
					this.changeNavTab(newVal);
				}
			}
		},
		currNavTab: {
			immediate: true,
			async handler (newVal: ImageGalleryDialogNav, oldVal: ImageGalleryDialogNav) {
				if (!this.isInitializedRunnitUploads && newVal === IMAGE_GALLERY_DIALOG_NAV.UPLOADS) {
					this.isInitializedRunnitUploads = true;
					await this.initializeRunnitUploads();
				}
				if (!this.isInitializedAvatars && newVal === IMAGE_GALLERY_DIALOG_NAV.AVATARS) {
					this.isInitializedAvatars = true;
					await this.initializeAvatars();
				}
			},
		},
		isIntersected: {
			immediate: true,
			async handler (newVal: boolean, oldVal: boolean) {
				if (
					this.insideNode &&
					newVal &&
					newVal !== oldVal &&
					this.runsGalleryRunnit &&
					this.runsGalleryNode &&
					this.runsGalleryRunnit.id === this.runsGalleryNode.runnitId
				) {
					await this.initializeRunnitNodeRuns(this.runsGalleryRunnit, this.runsGalleryNode);
				}
			},
		},
		runsGalleryRunnit: {
			immediate: true,
			async handler (newVal: Runnit, oldVal: Runnit) {
				const newId: string = this._get(newVal, 'id') || null;
				const oldId: string = this._get(oldVal, 'id') || null;
				if (
					this.insideNode &&
					newId &&
					newId !== oldId &&
					this.isIntersected &&
					this.runsGalleryNode &&
					newVal.id === this.runsGalleryNode.runnitId
				) {
					await this.initializeRunnitNodeRuns(newVal, this.runsGalleryNode);
				}
			},
		},
		runsGalleryNode: {
			immediate: true,
			async handler (newVal: RunnitNode, oldVal: RunnitNode) {
				const newId: string = this._get(newVal, 'id') || null;
				const oldId: string = this._get(oldVal, 'id') || null;
				if (
					this.insideNode &&
					newId &&
					newId !== oldId &&
					this.isIntersected &&
					this.runsGalleryRunnit &&
					this.runsGalleryRunnit.id === newVal.runnitId
				) {
					await this.initializeRunnitNodeRuns(this.runsGalleryRunnit, newVal);
				}
			},
		},
		runnitState: {
			immediate: true,
			async handler (newVal: RunnitState, oldVal: RunnitState) {
				if (
					!this.insideNode &&
					newVal &&
					(
						(!this._get(oldVal, 'imageGalleryDrawerOpen') && newVal.imageGalleryDrawerOpen) ||
						(!this._get(oldVal, 'imageGalleryDialogOpen') && newVal.imageGalleryDialogOpen)
					)
				) {
					await this.initializeRunnitNodeRuns(null);
				}
				if (newVal.deletedImages.nodeRunResults.length) {
					const deletedNodeRunResultsMap: Record<string, RunnitNodeRunResult> = _mapKeys(newVal.deletedImages.nodeRunResults, 'uuid');
					this.moreNodeRuns = this.moreNodeRuns.reduce((acc: RunnitNodeRun[], nodeRun: RunnitNodeRun) => {
						const nodeRunClone: RunnitNodeRun = _cloneDeep(nodeRun);
						nodeRunClone.results = (nodeRun.results || []).filter((nrr: RunnitNodeRunResult) => !deletedNodeRunResultsMap[nrr.uuid]);
						if (
							nodeRunClone.state !== RUNNIT_NODE_RUN_STATE.DONE ||
							nodeRunClone.results.length
						) {
							acc.push(nodeRunClone);
						}
						return acc;
					}, []);
				}
				if (newVal.deletedImages.uploads.length) {
					const deletedUploadsMap: Record<string, RunnitUpload> = _mapKeys(newVal.deletedImages.uploads, 'id');
					this.moreUploads = this.moreUploads.filter((upload: RunnitUpload) => !deletedUploadsMap[upload.id]);
				}
				if (newVal.deletedImages.avatars.length) {
					const deletedAvatarsMap: Record<string, Avatar> = _mapKeys(newVal.deletedImages.avatars, 'id');
					this.moreAvatars = this.moreAvatars.filter((avatar: Avatar) => !deletedAvatarsMap[avatar.id]);
				}
			}
		},
		realtimeNodeRuns: {
			immediate: true,
			async handler (newVal: RunnitNodeRun[]) {
				if (
					newVal &&
					newVal.length &&
					(
						newVal[0].userId !== this.user.id ||
						(this.insideNode && newVal[0].nodeId !== this.runsGalleryNode.id)
					)
				) {
					// RunnitNodeRun list doesn't belong to current RunnitNode or User
					return;
				}

				const newRealtimeNodeRuns: Record<string, RunnitNodeRun> = _mapKeys(newVal || [], 'id');
				const idsRemoved: string[] = this.oldRealtimeNodeRunIds.filter((id: string) => !newRealtimeNodeRuns[id]);
				if (idsRemoved.length) {
					idsRemoved.forEach(async (id: string) => {
						const nodeRunRef = db.collection('runnitNodeRuns').doc(id);
						const nodeRun: RunnitNodeRun = (await nodeRunRef.get()).data() as RunnitNodeRun;
						const nonDeletedResults = (nodeRun.results || []).filter((result: RunnitNodeRunResult) => !result.deletedAt);
						if (
							nodeRun.state !== RUNNIT_NODE_RUN_STATE.DONE ||
							nonDeletedResults.length
						) {
							this.moreNodeRuns = [
								{
									...nodeRun,
									results: nonDeletedResults,
									get id () { return nodeRunRef.id; }
								},
								...this.moreNodeRuns,
							];
						}
					});
				}

				this.oldRealtimeNodeRunIds = (newVal || []).map(({ id }) => id);
			},
		},
		realtimeUploads: {
			immediate: true,
			async handler (newVal: RunnitUpload[]) {
				if (
					newVal &&
					newVal.length &&
					newVal[0].userId !== this.user.id
				) {
					// RunnitUpload list doesn't belong to current User
					return;
				}

				const newRealtimeUploads: Record<string, RunnitUpload> = _mapKeys(newVal || [], 'id');
				const idsRemoved: string[] = this.oldRealtimeUploadIds.filter((id: string) => !newRealtimeUploads[id]);
				if (idsRemoved.length) {
					idsRemoved.forEach(async (id: string) => {
						const uploadRef = db.collection('runnitUploads').doc(id);
						const upload: RunnitUpload = (await uploadRef.get()).data() as RunnitUpload;
						this.moreUploads = [
							{
								...upload,
								get id () { return uploadRef.id; }
							},
							...this.moreUploads,
						];
					});
				}

				this.oldRealtimeUploadIds = (newVal || []).map(({ id }) => id);
			},
		},
		realtimeAvatars: {
			immediate: true,
			async handler (newVal: Avatar[]) {
				if (
					newVal &&
					newVal.length &&
					newVal[0].userId !== this.user.id
				) {
					// Avatar list doesn't belong to current User
					return;
				}

				const newRealtimeAvatars: Record<string, Avatar> = _mapKeys(newVal || [], 'id');
				const idsRemoved: string[] = this.oldRealtimeAvatarsIds.filter((id: string) => !newRealtimeAvatars[id]);
				if (idsRemoved.length) {
					idsRemoved.forEach(async (id: string) => {
						const avatarRef = db.collection('avatars').doc(id);
						const avatar: Avatar = (await avatarRef.get()).data() as Avatar;
						this.moreAvatars = [
							{
								...avatar,
								get id () { return avatarRef.id; }
							},
							...this.moreAvatars,
						];
					});
				}

				this.oldRealtimeAvatarsIds = (newVal || []).map(({ id }) => id);
			},
		},
	},
	methods: {
		...mapActions([
			'updateRunnitState',
			'updateSnackbar',
		]),
		_truncate,
		intersectHandler (entries) {
			if (entries.length && entries[0].isIntersecting) {
				this.isIntersected = true;
			}
		},
		getEnqueueResultsPlaceholder (nodeRun: RunnitNodeRun) {
			const length: number = this._get(nodeRun, `staticInputs[${RUNNIT_NODE_STATIC_FIELDS_KEY.numResults}]`) || 1;
			return _range(length);
		},
		async loadMoreNodeRuns (runnit: Runnit, node: RunnitNode) {
			try {
				this.loadingMoreNodeRuns = true;

				let nodeRunsRef = db.collection('runnitNodeRuns')
					.where('state', 'in', this.FINAL_NODE_RUN_STATES)
					.where('deletedAt', '==', null)
					/*
					- Don't order by createdAt, because drafts are created before the run is fired off
					- Order by Descending to show most recent at the top
					 */
					.orderBy('initAt', 'desc')
					.limit(this.moreNodeRunsLimit);

				if (runnit && node) {
					// index created = runnitNodeRuns: deletedAt Ascending nodeId Ascending runnitId Ascending state Ascending initAt Descending __name__ Descending
					nodeRunsRef = nodeRunsRef
						.where('nodeId', '==', node.id)
						.where('runnitId', '==', runnit.id);
				} else {
					// index created = runnitNodeRuns: deletedAt Ascending state Ascending userId Ascending initAt Descending __name__ Descending
					nodeRunsRef = nodeRunsRef
						.where('userId', '==', this.user.id);
				}

				let nodeRunsSnapshot;
				if (this.lastMoreNodeRunsDoc) {
					nodeRunsSnapshot = await nodeRunsRef.startAfter(this.lastMoreNodeRunsDoc).get();
				} else {
					nodeRunsSnapshot = await nodeRunsRef.get();
				}

				if (nodeRunsSnapshot.empty) {
					this.canLoadMoreNodeRuns = false;
					this.lastMoreNodeRunsDoc = null;
				} else {
					this.canLoadMoreNodeRuns = nodeRunsSnapshot.docs.length === this.moreNodeRunsLimit;
					this.lastMoreNodeRunsDoc = nodeRunsSnapshot.docs[nodeRunsSnapshot.docs.length - 1];

					const filteredMoreNodeRuns = nodeRunsSnapshot.docs.reduce((filteredRuns, doc) => {
						const nodeRun: RunnitNodeRun = doc.data() as RunnitNodeRun;
						const filteredResults = (nodeRun.results || []).filter((result: RunnitNodeRunResult) => !result.deletedAt);
						if (
							nodeRun.state !== RUNNIT_NODE_RUN_STATE.DONE ||
							filteredResults.length
						) {
							filteredRuns.push({
								...nodeRun,
								results: filteredResults,
								get id () { return doc.id },
							});
						}
						return filteredRuns;
					}, []);

					this.moreNodeRuns = [
						...this.moreNodeRuns,
						...filteredMoreNodeRuns,
					];
				}
			} catch (e) {
				console.error(e);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: 'Error loading more generations',
					show: true,
				});
			} finally {
				this.loadingMoreNodeRuns = false;
			}
		},
		resetMoreNodeRuns () {
			this.canLoadMoreNodeRuns = false;
			this.moreNodeRuns = [];
			this.lastMoreNodeRunsDoc = null;
		},
		async initializeRunnitNodeRuns (runnit: Runnit, node: RunnitNode) {
			try {
				this.resetMoreNodeRuns();
				this.loadMoreNodeRuns(runnit, node); // Don't await because both queries are already separated by different `state` where filters
				this.realtimeNodeRunsLoading = true;

				let nodeRunsRef = db.collection('runnitNodeRuns')
					.where('state', 'in', this.PENDING_NODE_RUN_STATES)
					.where('deletedAt', '==', null)
					/*
					- Don't order by createdAt, because drafts are created before the run is fired off
					- Order by Ascending because we're limiting the # of docs & then we will reverse on the frontend
					 */
					.orderBy('initAt', 'asc')
					.limit(this.realtimeNodeRunsLimit);

				if (runnit && node) {
					// index created = runnitNodeRuns: deletedAt Ascending nodeId Ascending runnitId Ascending state Ascending initAt Ascending __name__ Ascending
					nodeRunsRef = nodeRunsRef
						.where('nodeId', '==', node.id)
						.where('runnitId', '==', runnit.id);
				} else {
					// index created = runnitNodeRuns: deletedAt Ascending state Ascending userId Ascending initAt Ascending __name__ Ascending
					nodeRunsRef = nodeRunsRef
						.where('userId', '==', this.user.id);
				}

				await this.$bind(
					'realtimeNodeRuns',
					nodeRunsRef,
					get$bindFirestoreOptions({ reset: false }),
				);
			} catch (e) {
				console.error(e);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: 'Error loading generations',
					show: true,
				});
			} finally {
				this.realtimeNodeRunsLoading = false;
			}
		},
		async loadMoreUploads () {
			try {
				this.loadingMoreUploads = true;

				// index created = runnitUploads: deletedAt Ascending userId Ascending createdAt Descending __name__ Descending
				const uploadsRef = db.collection('runnitUploads')
					.where('deletedAt', '==', null)
					.where('userId', '==', this.user.id)
					.orderBy('createdAt', 'desc')
					.limit(this.moreUploadsLimit);

				let uploadsSnapshot;
				if (this.lastMoreUploadsDoc) {
					uploadsSnapshot = await uploadsRef.startAfter(this.lastMoreUploadsDoc).get();
				} else {
					uploadsSnapshot = await uploadsRef.get();
				}

				if (uploadsSnapshot.empty) {
					this.canLoadMoreUploads = false;
					this.lastMoreUploadsDoc = null;
				} else {
					this.canLoadMoreUploads = uploadsSnapshot.docs.length === this.moreUploadsLimit;
					this.lastMoreUploadsDoc = uploadsSnapshot.docs[uploadsSnapshot.docs.length - 1];

					// Mark the end of the `realtime` array here
					if (!this.moreUploads.length) {
						this.realtimeUploadsEndBeforeSnapshot = uploadsSnapshot.docs[0];
					}

					this.moreUploads = [
						...this.moreUploads,
						...uploadsSnapshot.docs.map(doc => ({
							...doc.data(),
							get id () { return doc.id },
						})),
					];
				}
			} catch (e) {
				console.error(e);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: 'Error loading more uploads',
					show: true,
				});
			} finally {
				this.loadingMoreUploads = false;
			}
		},
		resetMoreUploads () {
			this.canLoadMoreUploads = false;
			this.moreUploads = [];
			this.lastMoreUploadsDoc = null;
			this.realtimeUploadsEndBeforeSnapshot = null;
		},
		async initializeRunnitUploads () {
			try {
				this.resetMoreUploads();
				await this.loadMoreUploads();
				this.realtimeUploadsLoading = true;

				// index created = runnitUploads: deletedAt Ascending userId Ascending createdAt Descending __name__ Descending
				let uploadsRef = db.collection('runnitUploads')
					.where('deletedAt', '==', null)
					.where('userId', '==', this.user.id)
					.orderBy('createdAt', 'desc')
					.limit(this.realtimeUploadsLimit);

				if (this.realtimeUploadsEndBeforeSnapshot) {
					uploadsRef = uploadsRef.endBefore(this.realtimeUploadsEndBeforeSnapshot);
				}

				await this.$bind(
					'realtimeUploads',
					uploadsRef,
					get$bindFirestoreOptions({ reset: false }),
				);
			} catch (e) {
				console.error(e);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: 'Error loading uploads',
					show: true,
				});
			} finally {
				this.realtimeUploadsLoading = false;
			}
		},
		async loadMoreAvatars () {
			try {
				this.loadingMoreAvatars = true;

				// index created = avatars: deletedAt Ascending useCase Ascending userId Ascending createdAt Descending __name__ Descending
				const avatarsRef = db.collection('avatars')
					.where('deletedAt', '==', null)
					.where('userId', '==', this.user.id)
					.where('useCase', '==', 'RUNNIT')
					.orderBy('createdAt', 'desc')
					.limit(this.moreAvatarsLimit);

				let avatarsSnapshot;
				if (this.lastMoreAvatarsDoc) {
					avatarsSnapshot = await avatarsRef.startAfter(this.lastMoreAvatarsDoc).get();
				} else {
					avatarsSnapshot = await avatarsRef.get();
				}

				if (avatarsSnapshot.empty) {
					this.canLoadMoreAvatars = false;
					this.lastMoreAvatarsDoc = null;
				} else {
					this.canLoadMoreAvatars = avatarsSnapshot.docs.length === this.moreAvatarsLimit;
					this.lastMoreAvatarsDoc = avatarsSnapshot.docs[avatarsSnapshot.docs.length - 1];

					// Mark the end of the `realtime` array here
					if (!this.moreAvatars.length) {
						this.realtimeAvatarsEndBeforeSnapshot = avatarsSnapshot.docs[0];
					}

					this.moreAvatars = [
						...this.moreAvatars,
						...avatarsSnapshot.docs.map(doc => ({
							...doc.data(),
							get id () { return doc.id },
						})),
					];
				}
			} catch (e) {
				console.error(e);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: 'Error loading more avatars',
					show: true,
				});
			} finally {
				this.loadingMoreAvatars = false;
			}
		},
		resetMoreAvatars () {
			this.canLoadMoreAvatars = false;
			this.moreAvatars = [];
			this.lastMoreAvatarsDoc = null;
			this.realtimeAvatarsEndBeforeSnapshot = null;
		},
		async initializeAvatars () {
			try {
				this.resetMoreAvatars();
				await this.loadMoreAvatars();
				this.realtimeAvatarsLoading = true;

				// index created = avatars: deletedAt Ascending useCase Ascending userId Ascending createdAt Descending __name__ Descending
				let avatarsRef = db.collection('avatars')
					.where('deletedAt', '==', null)
					.where('userId', '==', this.user.id)
					.where('useCase', '==', 'RUNNIT')
					.orderBy('createdAt', 'desc')
					.limit(this.realtimeAvatarsLimit);

				if (this.realtimeAvatarsEndBeforeSnapshot) {
					avatarsRef = avatarsRef.endBefore(this.realtimeAvatarsEndBeforeSnapshot);
				}

				await this.$bind(
					'realtimeAvatars',
					avatarsRef,
					get$bindFirestoreOptions({ reset: false }),
				);
			} catch (e) {
				console.error(e);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: 'Error loading avatars',
					show: true,
				});
			} finally {
				this.realtimeAvatarsLoading = false;
			}
		},
		onNodeRunsContainerScrollTop () {
			this.$scrollTo(`#image-gallery-node-runs-anchor-${this.elIdSuffix}`, 800, {
				container: `#image-gallery-node-runs-container-${this.elIdSuffix}`,
			});
		},
		onUploadsContainerScrollTop () {
			this.$scrollTo(`#image-gallery-uploads-anchor-${this.elIdSuffix}`, 800, {
				container: `#image-gallery-uploads-container-${this.elIdSuffix}`,
			});
		},
		onAvatarsContainerScrollTop () {
			this.$scrollTo(`#image-gallery-avatars-anchor-${this.elIdSuffix}`, 800, {
				container: `#image-gallery-avatars-container-${this.elIdSuffix}`,
			});
		},
		onClose () {
			this.$emit('close');
		},
		determineWidth () {
			let width = '100%';

			if (
				this.$vuetify.breakpoint.smAndUp &&
				!this.insideDrawer
			) {
				width = '50%';
			}

			return width;
		},
		onNodeRunResultClick (nodeRun: RunnitNodeRun, nodeRunResult: RunnitNodeRunResult) {
			this.imageInfoCarouselConfig = {
				dialogOpen: !!(nodeRun && nodeRunResult),
				nodeRun,
				nodeRunResult,
			};
		},
		changeNavTab (tabId) {
			this.currNavTab = tabId;
			this.$emit('on-nav-tab-change', tabId);
		},
		handleSingleSelect (result, type: 'NodeRunResult' | 'Upload' | 'Avatar') {
			this[`on${type}SingleSelection`](result);
			this.$emit('selection-chosen', result);
		}
	},
	components: {
		ImageGalleryLoadMoreBtnsRow,
		RunnitNavTabs,
		LoadingState,
		ImageInfo,
		RunnitImage,
		BaseStyledMenu,
		GlassButton,
		ComplexBackground,
		RunnitImageInfoCarouselDialog,
		ImageUpload,
		RunnitResult,
	},
});
