import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"image-upload-container"},[_c('div',{on:{"dragover":function($event){$event.preventDefault();return _vm.handleDragOver.apply(null, arguments)},"dragleave":function($event){$event.preventDefault();return _vm.handleDragLeave.apply(null, arguments)},"drop":function($event){$event.preventDefault();return _vm.handleDrop.apply(null, arguments)},"click":_vm.openFileDialog}},[_vm._t("default",function(){return [_c('div',{key:"option-upload",staticClass:"image-upload-square",class:{
					'being-dragged-over': _vm.isBeingDraggedOver,
				},staticStyle:{"margin":"4px 0 0 0"}},[_c('div',{staticClass:"d-flex flex-column justify-center align-center"},[_c('div',{staticClass:"mb-2"},[(_vm.isUploading)?_c('div',[_vm._t("loading-icon",function(){return [_c(VProgressCircular,{attrs:{"indeterminate":true,"size":"24","width":"2"}})]})],2):(_vm.isBeingDraggedOver)?_c('div',[_vm._t("draggedOver-icon",function(){return [_c(VIcon,{staticClass:"gradient-text",staticStyle:{"font-size":"32px"}},[_vm._v("mdi-image-plus")])]})],2):_c('div',[_vm._t("icon",function(){return [_c(VIcon,{staticStyle:{"color":"var(--grey-650)","font-size":"28px"}},[_vm._v("mdi-image-plus-outline")])]})],2)]),_c('div',{staticClass:"upload-square-text-container",style:({
							...(_vm.isBeingDraggedOver && {
								color: 'white',
							}),
						})},[(_vm.isUploading)?_c('span',[_vm._t("loading-text",function(){return [_vm._v("Uploading...")]})],2):_c('span',[_vm._t("text",function(){return [_vm._v(" Click or drag image from your computer to "),_c('span',{class:{ 'gradient-text': !_vm.isBeingDraggedOver }},[_vm._v("Upload")])]})],2)])])])]})],2),_c('input',{ref:"fileInput",staticStyle:{"display":"none"},attrs:{"type":"file","id":"fileElem","multiple":false,"accept":"image/*"},on:{"change":_vm.onHiddenFileInputChange}})])
}
var staticRenderFns = []

export { render, staticRenderFns }