
import Vue from 'vue';
import { RUNNIT_NODE_DEF_TOOL_TYPE } from '@/constants/enums';
import GlassButton from '@/components/base/GlassButton.vue';
import RunnitImage from '@/views/Runnits/RunnitImage.vue';
import ComplexBackgroundOfferingCard from '@/views/Runnits/design/ComplexBackgroundOfferingCard.vue';
import ComplexBackgroundOfferingCardHorizontal
	from '@/views/Runnits/design/ComplexBackgroundOfferingCardHorizontal.vue';
import _isString from 'lodash/isString';

export default Vue.extend({
	name: 'OfferingCard',
	props: {
		title: { type: String, default: '' },
		imageUrl: { type: String, default: '' },
		avatar: { type: [Object, String], default: null },
		description: { type: String, default: '' },
		displayOnly: { type: Boolean, default: false },
		horizontal: { type: Boolean, default: false },
		publishedAt: { type: Object, default: null },
		showNotPublished: { type: Boolean, default: false },
		small: { type: Boolean, default: false },
		toolType: { type: String, required: true },
		isFeatured: { type: Boolean, required: false },
	},
	data () {
		return {
			RUNNIT_NODE_DEF_TOOL_TYPE,
		};
	},
	computed: {
		vertical () {
			return !this.horizontal;
		},
		computedContainerWidth () {
			let width: number = 300;

			if (this.vertical) {
				width = 300

				if (this.small) {
					width = 190;
				}
			}

			if (this.horizontal) {
				width = 460

				if (this.small) {
					width = 320;
				}
			}

			return width;
		},
		computedContainerHeight () {
			let height: number = 460;

			if (this.vertical) {
				height = 460

				if (this.small) {
					height = 320;
				}
			}

			if (this.horizontal) {
				height = 250

				if (this.small) {
					height = 150;
				}
			}

			return height;
		},
		computedImageHeightWidth () {
			let heightWidth: number = 240;

			if (this.vertical) {
				heightWidth = 240

				if (this.small) {
					heightWidth = 150;
				}
			}

			if (this.horizontal) {
				heightWidth = 200

				if (this.small) {
					heightWidth = 120;
				}
			}

			return heightWidth;
		},
		computedDescriptionCharLimit () {
			return this.small ? 77 : 200;
		},
		offeringCardStyles () {
			const styles = {
				background: 'var(--primary-purple-800)',
				backgroundImage: 'radial-gradient(circle at -30% 0, var(--primary-purple-300), rgba(255, 255, 255, 0) 70%)',
			};

			if (this.toolType === RUNNIT_NODE_DEF_TOOL_TYPE.TEAM) {
				styles.background = 'var(--blue-grey-800)';
				styles.backgroundImage = 'radial-gradient(circle at -30% 0, var(--blue-grey-500), rgba(255, 255, 255, 0) 70%)';
			} else if (this.toolType === RUNNIT_NODE_DEF_TOOL_TYPE.CORE) {
				styles.background = 'var(--dark-blue-800)';
				styles.backgroundImage = 'radial-gradient(circle at -30% 0, var(--dark-blue-500), rgba(255, 255, 255, 0) 70%)';
			} else if (this.toolType === RUNNIT_NODE_DEF_TOOL_TYPE.USER_CREATED) {
				styles.background = 'var(--dark-purple-1000)';
				styles.backgroundImage = `radial-gradient(circle at ${this.isFeatured ? '10%' : '-30%'} 0, var(--dark-purple-${this.isFeatured ? '500' : '700'}), rgba(255, 255, 255, 0) 70%)`;
			}

			return styles;
		},
		descriptionStyles () {
			const styles = {
				color: 'var(--primary-purple-100)',
			};

			if (this.toolType === RUNNIT_NODE_DEF_TOOL_TYPE.TEAM) {
				styles.color = 'var(--blue-grey-200)';
			} else if (this.toolType === RUNNIT_NODE_DEF_TOOL_TYPE.CORE) {
				styles.color = 'var(--dark-blue-200)';
			} else if (this.toolType === RUNNIT_NODE_DEF_TOOL_TYPE.USER_CREATED) {
				styles.color = 'var(--grey-200)';
			}

			return styles;
		},
	},
	components: {
		ComplexBackgroundOfferingCardHorizontal,
		GlassButton,
		ComplexBackgroundOfferingCard,
		RunnitImage,
	},
});
